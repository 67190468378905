.App {
  width: 100vw;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-align: center */
}

/* Left Col */

.img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -230px;
  margin-top: 10px;
}

img {
  border-radius: 50%;
  height: 330px;
}

.vl {
  margin-left: 30px;
  border-left: 1px solid grey;
  height: 330px;
}

/* Right Col */

.content {
  min-width: 700px;
}

.text-container {
  margin-left: 90px;
}

.greeting-head {
  font-size: 60px;
  font-weight: bold;
}

.greeting-text {
  margin-top: -10px;
  margin-bottom: -5px;
  font-size: 20px;
}

.about-container {
  margin-top: -5px;
  margin-left: -10px;
}

.about-text {
  font-size: 20px;
  list-style-type: none;
  line-height: 30px;
}

.about-link {
  color: black;
  text-decoration: none;
}

.about-link:hover {
  color: black;
}

ul {
  /* padding: 0; */
  list-style-type: none;
}

.links-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.links-group {
  width: 50%;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  justify-content: space-evenly;
}

.link-text {
  color: grey;
  text-decoration: none;
}

.link-text:hover {
  color: black;
}

/* Animations */

/* li {
  transition: font-size .2s ease-in .1s;
  font-size: 20px;
}
li:hover {
  transition: font-size .2s ease-in .1s;
  font-size: 21px;
} */

hr {
  /* float:left; */
  width: 100px;
  transition: width 1s;
}
hr:hover {
  transition: width 1s;
  width: 600px;
}

.ucla { 
  font-weight: bold;
  background-image: linear-gradient(rgba(41, 115, 176, 0.40), rgba(41, 115, 176, 0.40));
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-size: 100% 2.5px;
  transition: background-size .2s ease-in .1s;
}
.ucla:hover {
  transition: background-size .2s ease-in .1s;
  background-size: 100% 100%;
}

.aws {
  font-weight: bold;
  background-image: linear-gradient(rgba(255, 153, 0, 0.40), rgba(255, 153, 0, 0.40));
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-size: 100% 2.5px;
  transition: background-size .2s ease-in .1s;
}
.aws:hover {
  transition: background-size .2s ease-in .1s;
  background-size: 100% 100%;
}

.tbg {
  font-weight: bold;
  background-image: linear-gradient(rgba(36, 61, 82, 0.40), rgba(36, 61, 82, 0.40));
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-size: 100% 2.5px;
  transition: background-size .2s ease-in .1s;
}
.tbg:hover {
  transition: background-size .2s ease-in .1s;
  background-size: 100% 100%;
}

.koreos {
  font-weight: bold;
  background-image: linear-gradient(rgba(251, 4, 4, 0.40), rgba(251, 4, 4, 0.40));
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-size: 100% 2.5px;
  transition: background-size .2s ease-in .1s;
}
.koreos:hover {
  transition: background-size .2s ease-in .1s;
  background-size: 100% 100%;
}

.sama {
  font-weight: bold;
  background-image: linear-gradient(rgba(0, 187, 39, 0.40), rgba(0, 187, 39, 0.40));
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-size: 100% 2.5px;
  transition: background-size .2s ease-in .1s;
}
.sama:hover {
  transition: background-size .2s ease-in .1s;
  background-size: 100% 100%;
}


/* waving hand */
/* https://jarv.is/notes/css-waving-hand-emoji/ */

.wave:hover {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 1s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}

/* Screen size adjustments */


@media screen and (min-width: 1400px) {
  .links-container {
    margin-left: -100px;
  }
}
@media screen and (max-width: 1400px) {
  .img-container {
    margin-right: -180px;
  }
}
@media screen and (max-width: 1200px) {
  .img-container {
    margin-right: -120px;
  }
}
@media screen and (max-width: 992px) {
  .img-container {
    display: none;
  }
  .content {
    margin-left: -240px;
  }
}
@media screen and (max-width: 770px) {
  .content {
    margin-left: -270px;
  }
}
@media screen and (max-width: 575px) {
  .content {
    margin-left: -100px;
  }
}